import React from "react";
import { Col, Row } from "react-bootstrap";
import { ImTerminal } from "react-icons/im";
import {
  SiVisualstudiocode,
  SiInsomnia,
  SiPostman,
  SiSlack,
  SiIntellijidea,
  SiPycharm,
  SiMacos,
  SiDocker,
  SiIterm2,
  SiJupyter
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiMacos />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiDocker />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiIterm2 />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiJupyter />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiPycharm />
      </Col>
      <Col xs={4}  md={2} className="tech-icons">
        <SiIntellijidea />
      </Col>
      
      <Col xs={4} md={2} className="tech-icons">
        <SiInsomnia />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSlack />
      </Col>
    </Row>
  );
}

export default Toolstack;
