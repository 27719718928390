import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  // DiJavascript1,
  // DiReact,
  // DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiJava,
  DiAws,
  DiCoda,
  DiDatabase,
  DiDebian
} from "react-icons/di";
import {
  SiRedis,
  SiFirebase,
  SiNextdotjs,
  // SiSolidity,
  SiPostgresql,
} from "react-icons/si";
import { TbBrandGolang } from "react-icons/tb";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiJava />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiAws />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiDebian />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiCoda />
      </Col>

      {/* <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
      </Col> */}
      
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
      
      {/* <Col xs={4} md={2} className="tech-icons">
        <SiRedis />
      </Col> */}
      
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiDatabase />
      </Col>
    </Row>
  );
}

export default Techstack;
